<template>
  <!-- BEGIN: Content-->
  <div
    v-if="!loading"
    class="card"
  > <!-- estos paneles, de momento sólo los ve el admin -->
    <div
      v-b-toggle="`collapseGrantStats`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Stats</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseGrantStats`"
    >
      <div class="card-content ">
        <div class="row grants__stats">
          <div class="col-md-3">
            <div class="card">
              <div class="card-header pb-0">
                <h5>Grant money</h5>
              </div>
              <div class="card-body">
                <p class="display-5 mb-0">
                  {{ stats.grants.amount.toFixed(2) }} €
                </p>
                <p class="text-success">
                  {{ (stats.grants.historic_amount / stats.grants.total).toFixed(2) }} € average
                </p>
                <hr>
                <p class="mb-0">
                  <small><strong>{{ stats.grants.historic_amount.toFixed(2) }} €</strong> from {{ stats.grants.created_at }}</small>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-header pb-0">
                <h5>Grant personnel</h5>
              </div>
              <div class="card-body">
                <p class="display-5 mb-0">
                  {{ stats.grants.personnel }} people
                </p>
                <p class="text-success">
                  {{ (stats.grants.historic_personnel / stats.grants.total).toFixed(0) }} people average
                </p>
                <hr>
                <p class="mb-0">
                  <small><strong>{{ stats.grants.historic_personnel.toFixed(0) }} people</strong> from {{ stats.grants.created_at }}</small>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-header pb-0">
                <h5>Host support money</h5>
              </div>
              <div class="card-body">
                <p class="display-5 mb-0">
                  {{ stats.hosts.amount.toFixed(2) }} €
                </p>
                <p class="text-success">
                  {{ (stats.hosts.historic_amount / stats.hosts.total).toFixed(2) }} € average
                </p>
                <hr>
                <p class="mb-0">
                  <small><strong>{{ stats.hosts.historic_amount.toFixed(2) }} €</strong> from {{ stats.hosts.created_at }}</small>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-header pb-0">
                <h5>Host support personnel</h5>
              </div>
              <div class="card-body">
                <p class="display-5 mb-0">
                  {{ stats.hosts.personnel }} people
                </p>
                <p class="text-success">
                  {{ (stats.hosts.historic_personnel / stats.hosts.total).toFixed(0) }} people average
                </p>
                <hr>
                <p class="mb-0">
                  <small><strong>{{ stats.hosts.historic_personnel }} people</strong> from {{ stats.hosts.created_at }}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column pt-0">
          <div class="header-left">
            <h4 class="card-title" />
            <small><a
              href="#"
              class="text-primary"
            ><i data-feather="activity" /> View active</a> <a
              href="#"
              class="text-dark ms-2"
            ><i data-feather="archive" /> View history</a></small>
          </div>
          <div class="header-right d-flex align-items-center mt-sm-0 mt-1">
            <i data-feather="calendar" />
            <input
              type="text"
              class="form-control flat-picker border-0 shadow-none bg-transparent pe-0"
              placeholder="YYYY-MM-DD"
            >
          </div>
        </div>
        <div class="card-body">
          <canvas
            class="bar-chart-ex chartjs"
            data-height="400"
          />
        </div> -->
      </div>
    </b-collapse>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  data() {
    return {
      sortedField: 'Position',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
      ],
      loading: true,
      sortMapping: {
        Researcher: 'user.name',
      },
      title: 'Clean component',
    }
  },
  computed: {
    ...mapGetters({
      stats: 'grants/stats',
    }),
  },
  async mounted() {
    this.loading = false
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
}
</script>
